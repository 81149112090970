.comment-bubble {
   background-color: #4318FF; /* Light blue background */
   border-radius: 20px; /* Rounded corners */
   padding: 15px; /* Spacing inside the bubble */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   margin: 10px 0; /* Space between bubbles */
   max-width: 80%; /* Ensuring bubbles don’t stretch too wide */
 }
 
 .comment-bubble p {
   margin: 5px 0; /* Spacing between paragraphs or elements inside the bubble */
 }