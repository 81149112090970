/* VolunteerSocialMediaLanding.css */
.text-center {
  text-align: center;
}

.relative {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.absolute {
  transition: opacity 0.3s ease-in-out;
}
