.map-container {
  position: relative; /* Required for absolute positioning of the toggle button */
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.toggle-button-container {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 10; /* Make sure it's above the map */
}

.toggle-button {
  /* Button background */
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Optional: adds a shadow for better visibility */
  cursor: pointer;
}

.pulse {
  position: relative;
  width: 20px;
  height: 20px;
}

.pulse::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background: rgba(147, 116, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(5.5);
    opacity: 0;
  }
}

.marker {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.marker .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the marker */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.marker .tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.marker:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
