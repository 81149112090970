/* Ensure full height and centering */
html, body, #root {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

/* Navbar styles */
.navbar {
  position: fixed;
  top: 0; /* Adjusted for top of the page */
  width: 100%;
  z-index: 50;
  display: flex;
  justify-content: center; /* Ensure contents are centered */
  align-items: center;
  padding: 1rem; /* Adjust padding as necessary */
}

/* Navbar container */
.navbar-container {
  display: flex;
  justify-content: center; /* Center the nav links */
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust or remove max-width as per design requirements */
  margin: 0 auto;
}

.navbar-left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* Navbar links */
.navbar-links {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  backdrop-filter: blur(10px); /* Blur effect for navbar items */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  padding: 12px 24px;
  border-radius: 9999px; /* Rounded corners */
  background-color: rgba(0, 0, 0, 0.75); /* Darker transparent background for navbar items */
}

.navbar-links a {
  color: white; /* Normal text color */
  margin: 0 8px; /* Add some spacing between links */
}
.navbar-white {
}

.hamburger .line {
  background-color: black; /* Ensure the hamburger lines are visible on a white background */
}

.hamburger.open .line {
  background-color: white; /* Change the color of the lines to white when the menu is open */
}

.navbar-links-scrolled {
  backdrop-filter: blur(10px); /* Keep the blur effect when scrolled */
  background-color: rgba(0, 0, 0, 0.75); /* Slightly more opaque when scrolled */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
}

.nav-item {
  padding: 4px 8px; /* Adjust padding to make the navbar smaller */
  border-radius: 9999px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Lighter on hover */
}

.nav-item.active {
  background-color: white; /* Light background for active item */
  color: black; /* Dark text for active item */
}

/* Video container styles */
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: black;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.black-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1;
}

.video-container:hover .video {
  transform: scale(1.05);
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .video-container {
    height: 150vh;
  }
}

.relative {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.absolute {
  transition: opacity 0.3s ease-in-out;
}

/* Hamburger menu button */
.hamburger {
  display: none;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

/* Mobile menu styles */
.mobile-menu {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 60px; /* Adjust position as needed */
  right: 1rem; /* Align with right padding */
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  width: 200px;
  padding: 1rem;
  z-index: 1000;
}

.mobile-menu a {
  width: 100%;
  text-align: left;
  padding: 0.5rem 0;
  color: white;
  transition: background-color 0.3s ease;
}


.mobile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .navbar-container {
    justify-content: flex-start; /* Align contents to the start */
  }

  .navbar-left {
    flex: 1;
  }

  .navbar-links {
    display: none; /* Hide desktop links */
  }

  .hamburger {
    display: flex; /* Show hamburger on mobile */
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    width: 24px;
  height: 24px;
    justify-content: center;
    margin-right: 3;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
    z-index: 101; /* Ensure it's above the mobile menu */

  }
  .hamburger svg {
    color: #000; /* Ensure color is black */
    width: 24px;
    height: 24px;
  }
  .hamburger .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3s ease;
  }

  .hamburger .top {
    top: 0;
  }
  
  .hamburger .middle {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .hamburger .bottom {
    bottom: 0;
  }
  
  .hamburger.open .top {
    transform: rotate(45deg);
    top: 50%;
    color: white;
  }
  
  .hamburger.open .middle {
    opacity: 0;
  }
  
  .hamburger.open .bottom {
    transform: rotate(-45deg);
    bottom: 50%;
  }
  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(43, 41, 41, 0.981); /* Make the menu transparent */
    padding: 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 100; /* Lower than the button's z-index */
    overflow-y: auto; /* Allow scrolling if content exceeds viewport height */
  }
  
}
.mobile-menu .menu-heading {
  font-size: 1rem;
  margin-bottom: .3rem;
  color: rgb(107, 102, 102);
}
.mobile-menu .menu-item {
  padding: 0.5rem 0;
  font-size: 1.7rem;
  color: whitesmoke;

  transition: background 0.2s;
}
/* Media query for desktop devices */
@media (min-width: 769px) {
  .hamburger {
    display: none; /* Hide hamburger on desktop */
  }

}
.card {
  margin-top: 2rem;
  padding: 1.5rem;
  position: relative; /* Ensure text overlay stays on top */
  border-radius: 0.5rem;
  overflow: hidden; /* Hide overflow to keep text within bounds */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: 14rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

/* Rainbow gradient text */
.rainbow-gradient {
  background: linear-gradient(90deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff);
  background-size: 400% 400%;
  animation: gradient-animation 10s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
